import { TextField } from "@mui/material";
import React from "react";
export interface InputPropsTypes {
  className?: string;
  id: string;
  placeholder?: string;
  value?: string;
  type: string;
  disabled?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?(e?: any): void;
  error?: string;
  label?: string;
  inputProps?: {
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
  };
  helperText?: string;
  multiline?: boolean;
  rows?: number;
}

const Input: React.FC<InputPropsTypes> = (props) => (
  <div className="relative">
    <TextField
      id={props.id}
      name={props.id}
      type={props.type}
      label={props.label}
      variant="outlined"
      value={props.value}
      autoComplete="off"
      placeholder={props.placeholder} // nessesary for floating label
      className={`${props.className}`}
      disabled={props.disabled}
      InputProps={props.inputProps}
      helperText={props.helperText}
      multiline={props.multiline}
      rows={props.rows}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
      }}
      onBlur={(e) => {
        // setActive(false);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
    {props.error && (
      <span className="flex items-center font-medium text-error-main text-xs mt-1">
        {props.error}
      </span>
    )}
  </div>
);

Input.defaultProps = {
  className: undefined,
  placeholder: undefined,
  label: undefined,
  error: undefined,
  multiline: undefined,
  rows: undefined,
  inputProps: undefined,
  onBlur: () => null,
  onChange: () => null,
  value: undefined,
  helperText: undefined,
  disabled: undefined,
};

export default Input;
