import { TextField } from "@mui/material";
import React from "react";
import classes from "./styles.module.scss";

export interface InputPropsTypes {
  className?: string;
  id: string;
  placeholder?: string;
  value?: string;
  type: string;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?(e?: any): void;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  error?: string;
  label?: string;
  svg?: JSX.Element;
  disabled?: boolean;
}

const InputwithRightSvg: React.FC<InputPropsTypes> = (props) => (
  <div className="relative">
    <TextField
      id={props.id}
      name={props.id}
      type={props.type}
      label={props.label}
      variant="outlined"
      value={props.value}
      autoComplete="off"
      placeholder={props.placeholder} // nessesary for floating label
      className={`${props.className} ${classes.inputright_space}`}
      disabled={props.disabled}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
      }}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    />
    <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-10">{props.svg}</div>
    {props.error && (
      <span className="flex items-center font-medium text-error-main text-xs mt-1">
        {props.error}
      </span>
    )}
  </div>
);

InputwithRightSvg.defaultProps = {
  className: undefined,
  placeholder: undefined,
  label: undefined,
  error: undefined,
  onBlur: () => null,
  onClick: () => null,
  onChange: () => null,
  value: undefined,
  svg: undefined,
  disabled: undefined,
};

export default InputwithRightSvg;
