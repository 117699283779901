import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import TableViewIcon from "../../Icons/TableViewIcon";
import LandingPageLayout from "../../layout/LandingPageLayout";
import Button from "../CommonSection/Buttons/Button";
import Input from "../CommonSection/Input/Input";
import InputwithRightSvg from "../CommonSection/Input/InputwithRightSvg";
import classes from "./Login.module.scss";

const Register: React.FC = (): JSX.Element => {
  const router = useRouter();
  const [passwodrdInputType, setPasswodrdInputType] = useState("password");

  return (
    <LandingPageLayout>
      <div className={`${classes.login_section}`}>
        <div className={`${classes.login_container}`}>
          <div className={`${classes.login_page_title} font-display`}>Welcome to IntuCharge</div>
          <div className={`${classes.login_box}`}>
            <div className="w-full grid">
              <Image
                src="/images/loginimg.png"
                alt="Image"
                layout="responsive"
                width={994}
                height={619}
                objectFit="contain"
              />
            </div>
            <div className={`${classes.login_separates}`}>
              <div className={`${classes.login_card}`}>
                <h1 className={`${classes.login_title} font-display`}>Login To Your Account</h1>

                <h3 className={`${classes.login_section_text}`}>
                  Add your registered email and password
                </h3>

                <div className={`${classes.login_form_separates}`}>
                  <div className={`${classes.login_details_separates}`}>
                    <Input id="email" label="Email Address" type="text" />
                    <InputwithRightSvg
                      id="password"
                      label="Password"
                      type={passwodrdInputType}
                      svg={
                        <span
                          role="button"
                          aria-hidden="true"
                          className="cursor-pointer"
                          onClick={() => {
                            const inputType =
                              passwodrdInputType === "password" ? "text" : "password";
                            setPasswodrdInputType(inputType);
                          }}
                        >
                          <TableViewIcon />
                        </span>
                      }
                    />

                    <div className={`${classes.login_pw}`}>
                      <Link href="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>

                  <div className="mt-0">
                    <Button
                      className="w-[268px] lg:h-[47px]"
                      variant="primary"
                      onClick={() => {
                        router.push("/dashboard");
                      }}
                    >
                      Login
                    </Button>
                  </div>

                  <div className={`${classes.create_text}`}>
                    Don&apos;t have an account?{" "}
                    <span className="text-primary-main">
                      <Link href="/signup">Create Account.</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default Register;
