import Head from "next/head";
import React from "react";
import LoginComponent from "../components/Login/Login";
const Login: React.FC = (): JSX.Element => (
  <>
    <Head>
      <title>Login</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content="Login page" />
    </Head>
    <div>
      <LoginComponent />
    </div>
  </>
);

export default Login;
